/*
---------------------------------------------------------------------------------
	STRIPPED DOWN RESUME TEMPLATE
    html resume

    v0.9: 5/28/09

    design and code by: thingsthatarebrown.com
                        (matt brown)
---------------------------------------------------------------------------------
*/
/* //-- yui-grids style overrides -- */
/* //-- header, body, footer -- */
/* //-- footer -- */
/* //-- core typography and style -- */
/* //-- section styles -- */
/* --// override to force 1/8th width grids -- */
#doc2 {
  .msg {
	padding: 10px;
	background: #222;
	position: relative;
	h1 {
		color: #fff;
	}
	a {
		margin-left: 20px;
		background: #408814;
		color: white;
		padding: 4px 8px;
		text-decoration: none;
		&:hover {
			background: #266400;
		}
	}
}
body {
	font-family: 'Lora', serif;
	color: #444;
}
#inner {
	padding: 10px 80px;
	margin: 80px auto;
	background: #f5f5f5;
	border: solid #666;
	border-width: 8px 0 2px 0;
}
.yui-gf {
	margin-bottom: 2em;
	padding-bottom: 2em;
	border-bottom: 1px solid #ccc;
	.yui-u {
		width: 80.2%;
	}
	div.first {
		width: 12.3%;
	}
}
#hd {
	margin: 2.5em 0 3em 0;
	padding-bottom: 1.5em;
	border-bottom: 1px solid #ccc;
	h2 {
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	h1 {
		font-size: 48px;
		text-transform: uppercase;
		letter-spacing: 3px;
	}
}
#bd {
	margin-bottom: 2em;
}
#ft {
	margin-bottom: 2em;
	padding: 1em 0 5em 0;
	font-size: 92%;
	border-top: 1px solid #ccc;
	text-align: center;
	p {
		margin-bottom: 0;
		text-align: center;
	}
}
h2 {
	font-size: 152%;
	color: #333;
}
h3 {
	font-size: 122%;
	color: #333;
}
h4 {
	font-size: 122%;
	color: #333;
}
h1 {
	color: #333;
}
p {
	font-size: 100%;
	line-height: 18px;
	padding-right: 3em;
}
a {
	color: #990003;
	&:hover {
		text-decoration: none;
	}
}
strong {
	font-weight: bold;
}
li {
	line-height: 24px;
	border-bottom: 1px solid #ccc;
}
p.enlarge {
	font-size: 144%;
	padding-right: 6.5em;
	line-height: 24px;
	span {
		color: #000;
	}
}
.contact-info {
	margin-top: 7px;
}
.first {
	h2 {
		font-style: italic;
	}
}
.last {
	border-bottom: 0;
	border: none;
}
a#pdf {
	display: block;
	float: left;
	background: #666;
	color: white;
	padding: 6px 50px 6px 12px;
	margin-bottom: 6px;
	text-decoration: none;
	&:hover {
		background: #222;
	}
}
.job {
	position: relative;
	margin-bottom: 1em;
	padding-bottom: 1em;
	border-bottom: 1px solid #ccc;
	h4 {
		position: absolute;
		top: 0.35em;
		right: 0;
	}
	p {
		margin: 0.75em 0 3em 0;
	}
}
.skills-list {
	ul {
		margin: 0;
	}
	li {
		margin: 3px 0;
		padding: 3px 0;
		span {
			font-size: 152%;
			display: block;
			margin-bottom: -2px;
			padding: 0;
		}
	}
}
.talent {
	width: 32%;
	float: left;
	h2 {
		margin-bottom: 6px;
	}
}
#srt-ttab {
	margin-bottom: 100px;
	text-align: center;
	img.last {
		margin-top: 20px;
	}
}
}
